/* eslint-disable no-console */
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { NbxCapacitorFirebaseAuth } from '@nbx/capacitor-firebase-auth';
import { Capacitor } from '@capacitor/core';

import { NBXStorage } from '@nbx/frontend-helpers/storage';
import isMobileDevice from '../helpers/isMobileDevice';
import { createLogger } from '../helpers/Logger';
import importFirebase from './firebase-async';
import NBXUsers from './users.service';

let firebaseConfig = {};
let appInstance = null;
let importedFirebaseAuth = null;
let vippsOpenIDConnectProvider = null;
let googleOpenIDConnectProvider = null;
let appleOpenIDConnectProvider = null;

const log = createLogger('[firebase]');
const isAndroidBrowser = /(android)/i.test(navigator.userAgent);
const VIPPS_SCOPES = 'openid api_version_2 address birthDate name phoneNumber';

const Firebase = {
  setConfig: config => {
    if (isAndroidBrowser) {
      config.authDomain = config.authDomain.replace('app', 'auth');
    }
    return (firebaseConfig = config);
  },

  // https://firebase.google.com/docs/reference/js/app.md#initializeapp
  initializeApp: async config => {
    if (importedFirebaseAuth) return importedFirebaseAuth;

    const [firebaseApp, firebaseAuth] = await importFirebase();
    appInstance = firebaseApp.initializeApp(config);
    // See https://github.com/firebase/firebase-js-sdk/issues/5019
    if (window.PLATFORM !== 'web') {
      firebaseAuth.initializeAuth(appInstance, {
        persistence: firebaseAuth.indexedDBLocalPersistence
      });
    }

    vippsOpenIDConnectProvider = new firebaseAuth.OAuthProvider('oidc.vipps');
    vippsOpenIDConnectProvider.addScope(VIPPS_SCOPES);

    googleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('google.com');

    appleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('apple.com');

    importedFirebaseAuth = firebaseAuth;
    return importedFirebaseAuth;
  },

  importAuth: async () => {
    return Firebase.initializeApp(firebaseConfig);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getauth
  auth: () => Firebase.importAuth().then(firebaseAuth => firebaseAuth.getAuth(appInstance)),

  // https://firebase.google.com/docs/reference/js/auth.auth.md#auth_interface
  user: async () => {
    // if (Capacitor.isNativePlatform()) {
    //   const nativeUser = await FirebaseAuthentication.getCurrentUser();
    //   if (nativeUser?.user) {
    //     log.info('Using native user()');
    //     return nativeUser.user;
    //   }
    // }
    // we always sign in user in web sdk layer, native layer is used only for obtaning a token from providers
    return Firebase.auth().then(auth => auth.currentUser);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getidtoken
  getIdTokenFromNative: async forceRefresh => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        return FirebaseAuthentication.getIdToken({ forceRefresh }).then(token => token.token);
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  getIdToken: async forceRefresh => {
    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
  signUp: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#getadditionaluserinfo
  getAdditionalUserInfo: userCredential =>
    Firebase.importAuth().then(firebaseAuth => {
      return firebaseAuth.getAdditionalUserInfo(userCredential);
    }),

  onAuthStateChanged: async (nextOrObserver, error) => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.onAuthStateChanged(auth, nextOrObserver, error);
  },

  vippsReauthenticate: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const user = await Firebase.user();
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return firebaseAuth.reauthenticateWithPopup(user, vippsOpenIDConnectProvider);
  },

  vippsLogin: () => {
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithOpenIdConnect({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return Firebase.importAuth().then(firebaseAuth =>
      firebaseAuth.signInWithPopup(firebaseAuth.getAuth(), vippsOpenIDConnectProvider).then(result => {
        const { accessToken } = firebaseAuth.OAuthProvider.credentialFromResult(result);
        NBXUsers.vippsUserInfo(accessToken)
          .then(async userInfo => {
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'userInfo', JSON.stringify(userInfo));
            await NBXStorage.setItem('vippsUserInfo', JSON.stringify(userInfo));
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'stored user info');
          })
          .catch(error => {
            console.error('vippsLogin', error);
          });

        return result;
      })
    );
  },

  signInWithCredential: async (firebaseCredential, reauthenticate) => {
    const firebaseAuth = await Firebase.importAuth();
    console.log(
      'firebase.service.signInWithCredential firebaseCredential=',
      !!firebaseCredential,
      ' reauthenticate=',
      reauthenticate
    );
    try {
      const userCredential = await (reauthenticate
        ? firebaseAuth.reauthenticateWithCredential(await Firebase.user(), firebaseCredential)
        : firebaseAuth.signInWithCredential(await firebaseAuth.getAuth(), firebaseCredential));
      console.log('firebase.service.signInWithCredential userCredential=', !!userCredential);
      // native ios fix: if user signups and enrolls to mfa, we need native user to verify his phone number on ios
      if (Capacitor.getPlatform() === 'ios') {
        NbxCapacitorFirebaseAuth.signInWithCredential({ ...firebaseCredential });
      }
      return userCredential;
    } catch (signInErrorOrMfaRequired) {
      console.error('firebase.service.signInWithCredential error:', signInErrorOrMfaRequired);
      signInErrorOrMfaRequired.credential = firebaseCredential;
      throw signInErrorOrMfaRequired;
    }
  },

  login: async (email, password) => {
    const firebaseAuth = await Firebase.importAuth();
    console.log('firebase.service.login, firebaseAuth.signInWithEmailAndPassword');
    const firebaseCredential = firebaseAuth.EmailAuthProvider.credential(email, password);
    return Firebase.signInWithCredential(firebaseCredential, false);
    // return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
  },

  reauthenticateWithPassword: async (email, password) => {
    const firebaseAuth = await Firebase.importAuth();
    const firebaseCredential = firebaseAuth.EmailAuthProvider.credential(email, password);
    return Firebase.signInWithCredential(firebaseCredential, true);
  },

  signInWithGoogle: async ({ reauthenticate } = { reauthenticate: false }) => {
    const firebaseAuth = await Firebase.importAuth();
    if (Capacitor.isNativePlatform()) {
      console.log('firebase.service.signInWithGoogle reauthenticate=', reauthenticate);
      const oAuthResult = await FirebaseAuthentication.signInWithGoogle();
      console.log('firebase.service.signInWithGoogle oAuthResult=', !!oAuthResult);
      const firebaseCredential = firebaseAuth.GoogleAuthProvider.credential(oAuthResult.credential?.idToken);
      console.log('firebase.service.signInWithGoogle firebaseCredential=', !!firebaseCredential);
      return Firebase.signInWithCredential(firebaseCredential, reauthenticate);
    } else {
      const auth = await firebaseAuth.getAuth();
      const user = await Firebase.user();
      return reauthenticate
        ? firebaseAuth.reauthenticateWithPopup(user, googleOpenIDConnectProvider)
        : firebaseAuth.signInWithPopup(auth, googleOpenIDConnectProvider);
    }
  },

  signInWithApple: async ({ reauthenticate } = { reauthenticate: false }) => {
    const firebaseAuth = await Firebase.importAuth();
    if (Capacitor.isNativePlatform()) {
      console.log('firebase.service.signInWithApple reauthenticate=', reauthenticate);
      const oAuthResult = await FirebaseAuthentication.signInWithApple();
      console.log('firebase.service.signInWithApple oAuthResult=', !!oAuthResult);
      const firebaseCredential = appleOpenIDConnectProvider.credential({
        providerId: 'apple.com',
        idToken: oAuthResult.credential.idToken,
        rawNonce: oAuthResult.credential.nonce
      });
      console.log('firebase.service.signInWithApple firebaseCredential=', !!firebaseCredential);
      return Firebase.signInWithCredential(firebaseCredential, reauthenticate);
    } else {
      const auth = await firebaseAuth.getAuth();
      const user = await Firebase.user();
      return reauthenticate
        ? firebaseAuth.reauthenticateWithPopup(user, appleOpenIDConnectProvider)
        : firebaseAuth.signInWithPopup(auth, appleOpenIDConnectProvider);
    }
  },

  getRedirectResult: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.getRedirectResult(auth);
  },

  isRedirectFlow: () => isMobileDevice(),

  // https://firebase.google.com/docs/reference/js/auth.md#signout
  logout: async () => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native signout()');
        return FirebaseAuthentication.signOut();
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.signOut(auth);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#reload
  reloadUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.reload(user);
    }),

  reauthenticate: (email, password) => {
    // We rely on authservice to maintain sessions and so don't maintain a Firebase session
    // so to "reauthenticate" we just login again
    return Firebase.login(email, password);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#sendpasswordresetemail
  resetPassword: email =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.sendPasswordResetEmail(auth, email);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#sendemailverification
  sendEmailVerification: settings =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.sendEmailVerification(user, settings);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#checkactioncode
  checkActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.checkActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#applyactioncode
  applyActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.applyActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#confirmpasswordreset
  confirmPasswordReset: (actionCode, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.confirmPasswordReset(auth, actionCode, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updateemail
  changeEmail: newEmail =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updateEmail(user, newEmail);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updatepassword
  changePassword: password =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updatePassword(user, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#deleteuser
  deleteUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.deleteUser(user);
    }),

  createRecaptchaVerifier: ({ containerId, options }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return new firebaseAuth.RecaptchaVerifier(
        auth,
        containerId,
        window.PLATFORM === 'ios' ? { ...options, 'auth-type': 'ios' } : options
      );
    }),

  multiFactorUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.multiFactor(user);
    }),

  getMultiFactorResolver: error =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.getMultiFactorResolver(auth, error);
    }),

  verifyPhoneNumber: ({ phoneInfoOptions, recaptchaVerifier }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      console.log(
        'firebase.service.verifyPhoneNumber, create PhoneAuthProvider, phoneInfoOptions = ',
        !!phoneInfoOptions,
        'recaptchaVerifier = ',
        !!recaptchaVerifier
      );
      const phoneAuthProvider = new firebaseAuth.PhoneAuthProvider(auth);
      console.log(
        'firebase.service.verifyPhoneNumber, preparing sending sms, renderring recaptchaVerifier, phoneAuthProvider = ',
        !!phoneAuthProvider
      );
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    }),

  multiFactorAssertion: ({ verificationId, verificationCode }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const credential = firebaseAuth.PhoneAuthProvider.credential(verificationId, verificationCode);
      console.log('firebase.service.multiFactorAssertion, verify sms', !!credential);
      return firebaseAuth.PhoneMultiFactorGenerator.assertion(credential);
    }),

  enrollMultiFactorUser: ({ verificationId, verificationCode }) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      console.log('firebase.service.enrollMultiFactorUser, multiFactorAssertion');
      const multiFactorAssertion = await Firebase.multiFactorAssertion({ verificationId, verificationCode });
      console.log('firebase.service.enrollMultiFactorUser, enroll user', !!multiFactorAssertion);
      return firebaseAuth.multiFactor(user).enroll(multiFactorAssertion);
    })
};

export default Firebase;
